// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// primary: Denim Blue #2243B6
// accent: Resolution Blue #002387
// warn: International Orange Aerospace #FF4F00

/* For use in src/lib/core/theming/_palette.scss */
$fyp-primary: (
  50: #fef4e4,
  100: #fde3bc,
  200: #fcd08f,
  300: #fbbd62,
  400: #faae41,
  500: #f9a01f,
  600: #f8981b,
  700: #f78e17,
  800: #f68412,
  900: #f5730a,
  A100: #ffffff,
  A200: #fff3eb,
  A400: #ffd4b8,
  A700: #ffc59e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$fyp-accent: (
  50: #feeee2,
  100: #fcd5b6,
  200: #fab985,
  300: #f89d54,
  400: #f7882f,
  500: #f5730a,
  600: #f46b09,
  700: #f26007,
  800: #f05605,
  900: #ee4303,
  A100: #ffffff,
  A200: #ffe8e1,
  A400: #ffc0ae,
  A700: #ffac95,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$fyp-warn: (
  50: #ffeae0,
  100: #ffcab3,
  200: #ffa780,
  300: #ff844d,
  400: #ff6926,
  500: #ff4f00,
  600: #ff4800,
  700: #ff3f00,
  800: #ff3600,
  900: #ff2600,
  A100: #ffffff,
  A200: #fff3f2,
  A400: #ffc5bf,
  A700: #ffaea6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */

$fyp-wls-dashboard-primary: mat.define-palette($fyp-primary);
$fyp-wls-dashboard-accent: mat.define-palette($fyp-accent);
$fyp-wls-dashboard-warn: mat.define-palette($fyp-warn);

@include mat.core();

$typography: mat.define-typography-config(
  $font-family: "Varela Round",
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// INFO this is a devil
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($typography);`

// The warn palette is optional (defaults to red).
// $fyp-wls-dashboard-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fyp-wls-dashboard-theme: mat.define-light-theme(
  (
    color: (
      primary: $fyp-wls-dashboard-primary,
      accent: $fyp-wls-dashboard-accent,
      warn: $fyp-wls-dashboard-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

@include mat.typography-hierarchy($typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
$primary: map-get($fyp-wls-dashboard-theme, "primary", "default");
$light: map-get($fyp-wls-dashboard-theme, "primary", "lighter");
$secondary: map-get($fyp-wls-dashboard-theme, "accent", "default");
$warning: map-get($fyp-wls-dashboard-theme, "warn", "default");
$danger: map-get($fyp-wls-dashboard-theme, "warn", 700);

@import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/utilities";

// INFO BOOTSTRAP UTILITIES
$utilities: (
  "width": (
    property: width,
    responsive: true,
    class: w,
    values:
      map-merge(
        map-get($utilities, "width", "values"),
        (
          90: 90%,
          60: 60%,
          40: 40%,
          35: 35%,
          20: 20%,
          fit-content: fit-content,
        )
      ),
  ),
  "height": (
    property: height,
    responsive: true,
    class: h,
    values:
      map-merge(
        map-get($utilities, "height", "values"),
        (
          90: 90%,
          60: 60%,
          40: 40%,
          35: 35%,
          20: 20%,
          fit-content: fit-content,
        )
      ),
  ),
  "min-width": (
    property: min-width,
    responsive: true,
    class: mnw,
    values: (
      100: 100%,
      75: 75%,
      50: 50%,
      40: 40%,
      35: 35%,
    ),
  ),
  "max-width": (
    property: max-width,
    responsive: true,
    class: mxw,
    values: (
      100: 100%,
      75: 75%,
      50: 50%,
      40: 40%,
      35: 35%,
    ),
  ),
  "z-index": (
    property: "z-index",
    responsive: true,
    class: z-index,
    values: (
      1: 1,
      10: 10,
      20: 20,
    ),
  ),
  "left": (
    property: "left",
    responsive: true,
    class: left,
    values: (
      50: 50%,
    ),
  ),
);

@import "bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Varela+Round:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");
@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0);

@include mat.all-component-themes($fyp-wls-dashboard-theme);

/* You can add global styles to this file, and also import other style files */
@mixin customize-tab(
  $target: "primary",
  $tab-border-radius: $border-radius-lg,
  $theme: $fyp-wls-dashboard-theme
) {
  nav {
    &.mat-mdc-tab-nav-bar.mat-mdc-tab-header {
      border: 0;

      ::ng-deep {
        > div.mat-mdc-tab-link-container {
          > div.mat-mdc-tab-list {
            > div.mat-mdc-tab-links {
              > a.mdc-tab.mat-mdc-tab-link.mdc-tab--active {
                border-radius: $tab-border-radius;
                background-color: map-get($theme, $target, "default");

                > .mdc-tab__content .mdc-tab__text-label {
                  color: map-get($theme, $target, "default-contrast");
                }

                > .mdc-tab-indicator {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

// @mixin customize-tab($target, $theme: $fyp-wls-dashboard-theme) {
//   nav {
//     &.mat-tab-nav-bar.mat-tab-header {
//       border: 0;

//       ::ng-deep {
//         > div.mat-tab-link-container {
//           > div.mat-tab-list {
//             > div.mat-tab-links {
//               > a.mat-tab-link.mat-tab-label-active {
//                 // color: map-get($theme, $target, "default-contrast");
//                 border-radius: 8px;
//                 background-color: map-get($theme, $target, "default");
//               }
//             }

//             > mat-ink-bar.mat-ink-bar {
//               display: none;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@mixin mat-form-field-padding($val: 0, $font-size: 13px) {
  mat-form-field {
    font-size: $font-size;

    &.mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-form-field-appearance-outline::ng-deep {
      > div.mat-mdc-text-field-wrapper {
        height: $val;
      }
    }
  }
}

@mixin headless-mat-stepper() {
  // mat-horizontal-stepper {
  ::ng-deep {
    div.mat-horizontal-stepper-header-container {
      display: none;
    }

    div.mat-horizontal-content-container {
      padding: 0;
    }

    div.mat-vertical-stepper-header-container {
      display: none;
    }

    div.mat-vertical-content-container {
      padding: 0;
    }
  }
  // }
}

@mixin no-outline {
  outline: none !important;
  outline-style: none !important;
}

@mixin normalize-anchor {
  text-decoration: none !important;
  color: inherit !important;

  &:hover {
    color: auto !important;
    text-decoration: none !important;
  }
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  width: 100vw;
  overflow: hidden auto;
  scroll-behavior: smooth;
  background-color: map-get($fyp-wls-dashboard-theme, "primary", 50);
  background-image: url(https://storage.googleapis.com/gci-static-assets/imole/imole-icon-light.svg);
  background-position: bottom right;
  background-size: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @include media-breakpoint-up(lg) {
    background-size: 25%;
  }

  a {
    @include normalize-anchor();
  }

  input,
  textarea {
    @include no-outline();
  }

  textarea {
    resize: none !important;
  }
}

.ctx--dialog__container {
  & > mat-dialog-container.mat-dialog-container {
    border-radius: 10px !important;
    padding: 0 !important;
    color: #565656;
    background-color: #ffffff;
    height: auto;
    max-height: 80% !important;

    @media (max-width: 575.98px) {
      border-radius: 0 !important;
    }
  }
}

@media (orientation: landscape) {
  div.mat-datepicker-dialog {
    & > mat-datepicker-content.mat-datepicker-content {
      max-height: 95vh;

      div.mat-datepicker-content-container {
        height: 95vh;
      }
    }
  }
}

@media (orientation: portrait) {
  div.mat-datepicker-dialog {
    & > mat-datepicker-content.mat-datepicker-content {
      max-height: 95vh;

      div.mat-datepicker-content-container {
        height: 100vw;
      }
    }
  }
}
